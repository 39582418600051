<template>
    <div>
        <div v-if="this.currentIssueId !== null">
            <div class="row">
                <div class="col-lg-offset-2 col-lg-8">
                    <div class="box box-widget widget-user-2">
                        <div class="widget-user-header">
                            <div class="row">
                                <div v-if="!editTgl">
                                    <div class="col-md-7" style="display: flex; align-items: center">
                                        <h3 style="margin-left: 0px;" class="widget-user-username">{{currentIssue.name}}</h3>
                                        <h4 style="margin-left: 1rem">{{formResponses.visible_id}}</h4>
                                    </div>
                                    <div class="col-md-5">
                                        <h3 style="margin-top: 5px" class="pull-right">
                                            <span :style="`color: ${currentIssue.tag_color}; font-weight: bold; display: inline`">
                                                {{currentIssue.tag_title}}
                                            </span>
                                        </h3>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="col-md-6">
                                        <div class="form-group" :class="{'has-error': $v.formResponses.name.$error}">
                                            <label for="inputName" class="control-label">Název úkonu</label>
                                            <input id="inputName" class="form-control" v-model="$v.formResponses.name.$model" disabled type="text">
                                            <span v-if="!$v.formResponses.name.required" class="help-block">Povinný údaj</span>
                                            <span v-if="!$v.formResponses.name.maxLength" class="help-block">Může mít maximálně 128 znaků!</span>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="visibleId" class="control-label">Číslo servisního úkonu</label>
                                        <input id="visibleId" class="form-control" v-model="$v.formResponses.visible_id.$model" type="number" readonly="true" ondblclick="this.readOnly='';" onblur="this.readOnly='true';">
                                        <span v-if="$v.formResponses.visible_id.$dirty" class="help-block">Číslo nového úkonu musí být větší jak číslo posledního úkonu: {{ nextVisibleId - 1 }}</span>
                                        <span v-else class="help-block">Klikněte dvakrát na pole pro úpravu tohoto čísla.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box-footer print-hide" v-if="!editTgl">
                            <button style="margin-right: 1rem" v-if="currentIssue.tagid == 3 && !currentIssue.invoice_id && !user.cus && invoiceInfo.invoice && this.ops.length" class="btn btn-success" @click="invoice()">Vytvořit fakturu</button>
                            <button style="margin-right: 1rem" v-if="currentIssue.tagid == 3 && currentIssue.invoice_id && !user.cus && invoiceInfo.invoice" class="btn btn-success" @click="invoice()">Zobrazit fakturu</button>
                            <button v-if="!user.cus" class="btn btn-success" @click="generatePrintIssue()">Tisk</button>
                            <button style="display: none" v-if="currentIssue.tagid == 1 && !user.cus" class="btn btn-success" @click="issueStageChange(3)">Mám hotovo</button>
                            <button v-if="!user.cus && currentIssue.tagid != 3 && !currentIssue.invoice_id" type="button" class="btn btn-success pull-right" style="border: none; margin-left: 10px" @click="issueStageChange(3)"><span>Uzavřít úkon</span></button>
                            <button v-if="!user.cus && currentIssue.tagid == 3" type="button" class="btn btn-success pull-right" style="border: none; margin-left: 10px" @click="reopenIssue()"><span>Znovu otevřít úkon</span></button>
                            <button v-if="!currentIssue.invoice_id && currentIssue.tagid != 3" type="button" class="btn btn-default pull-right" style="border: none; margin-left: 10px" @click="editIssue()"><span><i class="fa fa-edit"></i> Upravit</span></button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="box box-widget">
                                <div class="box-body no-padding">
                                    <div class="col-lg-4 no-padding">
                                        <ul class="nav nav-stacked">
                                            <li v-if="!user.cus && (!editTgl || editTgl && currentIssue.tagid != 1)"><a href="#">Termín opravy od:<a class="pull-right">{{getTime(currentIssue.due_from)}}</a></a></li>
                                            <li v-if="!user.cus && (editTgl && currentIssue.tagid == 1)">
                                                <div style="padding: 10px 15px">
                                                    <label>Termín opravy od</label>
                                                    <div class="row">
                                                        <div class="col-xs-7" style="padding-right: 0px">
                                                            <v-date-picker mode="dateTime" v-model="fromdate" class="flex-grow" is24hr :min-date="Date.now()">
                                                                <input slot-scope="{ inputProps, inputEvents, inputValue }" v-bind="inputProps" :value="inputValue" v-on="inputEvents" id="fromdate" class="form-control" autocomplete="off">
                                                            </v-date-picker>
                                                            <span v-if="!fromdate" class="help-block">Povinný údaj</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li v-if="!user.cus && (!editTgl || editTgl && currentIssue.tagid != 1)"><a href="#">Termín opravy do:<a class="pull-right">{{getTime(currentIssue.due_to)}}</a></a></li>
                                            <li v-if="!user.cus && (editTgl && currentIssue.tagid == 1)">
                                                <div style="padding: 10px 15px">
                                                    <label>Termín opravy do</label>
                                                    <div class="row">
                                                        <div class="col-xs-7" style="padding-right: 0px">
                                                            <v-date-picker mode="dateTime" v-model="todate" class="flex-grow" is24hr :min-date="fromdate">
                                                                <input slot-scope="{ inputProps, inputEvents, inputValue }" :value="inputValue" v-bind="inputProps" v-on="inputEvents" id="todate" class="form-control" autocomplete="off">
                                                            </v-date-picker>
                                                            <span v-if="!todate" class="help-block">Povinný údaj</span>
                                                            <span v-if="Date.parse(fromdate) > Date.parse(todate)" class="help-block">Špatný čas termínu dokončení</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div v-if="!user.cus && (editTgl && currentIssue.tagid == 1)"> <!-- currentIssue.resevation -->
                                                    <div style="padding: 10px 15px" :class="{'has-error': $v.formResponses.issue_price.$error}">
                                                        <label for="name" class="control-label">Předpokládaná cena zakázky</label>
                                                        <input id="name" class="form-control" v-model="$v.formResponses.issue_price.$model" type="number">
                                                        <span v-if="!$v.formResponses.issue_price.required" class="help-block">Povinný údaj</span>
                                                        <span v-if="!$v.formResponses.issue_price.decimal" class="help-block">Musí být číslo!</span>
                                                        <span v-if="!$v.formResponses.issue_price.minValue" class="help-block">Číslo musí být kladné!</span>
                                                    </div>
                                                </div>
                                                <a v-else @click="(e) => e.preventDefault()">Předpokládaná cena zakázky:
                                                    <span class="pull-right">{{currentIssue.issue_price}} Kč</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-4 no-padding">
                                        <ul class="nav nav-stacked">
                                            <li v-if="!user.cus">
                                                <a v-if="!currentIssue.reservation" href="#" @click="makeCurrentCus(currentIssue.customerid)">
                                                    Zákazník<a class="pull-right">{{currentIssue.company_name}}</a>
                                                </a>
                                                <a v-else>
                                                    <div v-if="editTgl">
                                                        <label>Zákazník</label>
                                                        <div class="input-group">
                                                            <div class="input-group-addon">
                                                                <i class="fa fa-address-book"></i>
                                                            </div>
                                                            <Select2 v-model="customerid" :options="minCustomers" id="selectCus" @select="onCustomerSelect($event)" />
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        Zákazník<span class="pull-right">{{currentIssue.company_name}}</span>
                                                    </div>
                                                </a>
                                            </li>
                                            <li><a href="javascript:void(0)">Ulice:<span class="pull-right">{{currentCustomer.street}}</span></a></li>
                                            <li><a href="javascript:void(0)">Město:<span class="pull-right">{{currentCustomer.city}}</span></a></li>
                                            <li><a href="javascript:void(0)">Řidič:<span class="pull-right">{{currentMaintainer.fullname}}</span></a></li>
                                            <li><a href="javascript:void(0)">Tel.:<span class="pull-right">{{currentMaintainer.phone}}</span></a></li>
                                            <li><a href="javascript:void(0)">Email:<span class="pull-right">{{currentMaintainer.email}}</span></a></li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-4 no-padding">
                                        <ul class="nav nav-stacked">
                                            <li><a href="javascript:void(0)">Auto:<span class="pull-right">{{currentIssue.car_name}}</span></a></li>
                                            <li><a href="javascript:void(0)">VIN:<span class="pull-right">{{currentIssue.vin_code}}</span></a></li>
                                            <li><a href="javascript:void(0)">SPZ:<span class="pull-right">{{currentIssue.licence_plate}}</span></a></li>
                                            <li><a href="javascript:void(0)">STK:<span class="pull-right">{{convertToStkTime(currentIssue.stk_expire)}}</span></a></li>
                                            <li><a href="javascript:void(0)">Jedná se o kontrolu STK:<span class="pull-right">{{currentIssue.stk_check.data[0] == 1 ? "Ano" : "Ne"}}</span></a></li>
                                            <li v-if="!editTgl"><a href="javascript:void(0)">Stav tachometru:<span class="pull-right">{{currentIssue.kilometers}} km</span></a></li>
                                            <li v-else style="padding: 1.2rem">
                                                <label for="kilometers" class="control-label">Stav tachometru</label>
                                                <div class="input-group">
                                                    <input id="kilometers" class="form-control" v-model="formResponses.kilometers" type="number">
                                                    <div class="input-group-addon" style="background-color: #d2d6de">
                                                        km
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="box box-widget">
                                <div class="box-body">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <ul class="nav nav-stacked">
                                                <li><a @click="(e) => e.preventDefault()">Přidáno:<span class="pull-right">{{getDate(currentIssue.added)}}</span></a></li>
                                                <li><a @click="(e) => e.preventDefault()">Uzavřeno:<span class="pull-right">{{getDate(currentIssue.closed)}}</span></a></li>
                                            </ul>
                                        </div>
                                        <div class="col-lg-6">
                                            <ul class="nav nav-stacked">
                                                <li><a @click="(e) => e.preventDefault()">Částka bez DPH:<span class="pull-right">{{formResponses.final_price_no_dph}}</span></a></li>
                                                <li v-if="!user.cus"><a @click="(e) => e.preventDefault()">Částka s DPH:<span class="pull-right dph">{{formResponses.final_price_dph}}</span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="box box-widget">
                                <div class="box-body">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div :class="{'form-group': true, 'has-error': $v.formResponses.comment.$error}">
                                                <label for="inputComment" class="control-label">Popis závady zákazníkem</label><span v-if="editTgl" class="pull-right">({{formResponses.comment.length}}/1024)</span>
                                                <div>
                                                    <textarea :readonly="!editTgl" maxlength="1024" id="inputComment" v-model="$v.formResponses.comment.$model" :style="`resize: none; background-color: white; ${editTgl ? '' : 'cursor: default'}`" class="form-control" rows="2" :placeholder="editTgl ? 'Komentář k úkonu' : ''"></textarea>
                                                    <span v-if="!$v.formResponses.comment.maxLength" class="help-block">Může mít maximálně 1024 znaků!</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                        </div>
                                        <div v-if="!user.cus" class="col-lg-6 print-hide">
                                            <div :class="{'form-group': true, 'has-error': $v.formResponses.hidden_comment.$error}">
                                                <label for="inputHiddenComment" class="control-label">Skrytý komentář</label><span v-if="editTgl" class="pull-right">({{formResponses.hidden_comment.length}}/1024)</span>
                                                <div>
                                                    <textarea :readonly="!editTgl" maxlength="1024" id="inputHiddenComment" v-model="$v.formResponses.hidden_comment.$model" :style="`resize: none; background-color: white; ${editTgl ? '' : 'cursor: default'}`" class="form-control" rows="2" :placeholder="editTgl ? 'Tento komentář neuvidí zákazník' : ''"></textarea>
                                                    <span v-if="!$v.formResponses.hidden_comment.maxLength" class="help-block">Může mít maximálně 1024 znaků!</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="margin: 0;">
                            <div class="col-md-12">
                                <div class="box box-widget widget-user-2">
                                    <div class="box-header with-border" v-if="editTgl && !user.cus">
                                        <div class="col-md-6" style="padding: 0 5px">
                                            <label for="select-type" class="control-label">Načíst operaci ze seznamu</label>
                                            <Select2 v-model="operation.listOperation" @change="setListOperation()" :options="getListOperationDescriptions()" id="select-type"/>
                                        </div>
                                    </div>
                                    <div class="box-footer" v-if="editTgl && !user.cus">
                                        <div class="col-md-12" style="padding: 0">
                                            <div class="col-md-2" style="padding: 0 5px">
                                                <label for="number" class="control-label">Číslo operace</label>
                                                <input id="number" class="form-control" type="text" :value="operation.order" style="text-align:right">
                                            </div>
                                            <div class="col-md-3" style="padding: 0 5px">
                                                <div :class="{'has-error': $v.operation.description.$error}">
                                                    <label for="name" class="control-label">Název operace</label>
                                                    <input id="name" class="form-control" v-model="$v.operation.description.$model" type="text">
                                                    <span v-if="!$v.operation.description.required" class="help-block">Povinný údaj</span>
                                                    <span v-if="!$v.operation.description.maxLength" class="help-block">Může mít maximálně {{$v.operation.description.$params.maxLength.max}} znaků!</span>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div :class="{'has-error': $v.operation.description.$error}">
                                                    <label for="name" class="control-label">Typ</label>
                                                    <!-- <input id="name" class="form-control" v-model="$v.operation.description.$model" type="text"> -->
                                                    <Select2 v-model="operation.type" :options="['Práce', 'Materiál']"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12" style="padding: 0">
                                            <div v-if="this.operation.type">
                                                <div class="col-md-2" style="padding: 0 5px">
                                                    <div :class="{'has-error': $v.operation.amount.$error}">
                                                        <label for="amount" class="control-label" v-if="operation.type == 'Materiál'">Množství</label>
                                                        <label for="amount" class="control-label" v-else>Doba trvání operace</label>
                                                        <input id="amount" class="form-control" v-model="$v.operation.amount.$model" type="number">
                                                        <span v-if="!$v.operation.amount.required" class="help-block">Povinný údaj</span>
                                                        <span v-if="!$v.operation.amount.decimal" class="help-block">Musí být číslo!</span>
                                                        <span v-if="!$v.operation.amount.minValue" class="help-block">Číslo musí být kladné!</span>
                                                    </div>
                                                </div>
                                                <div class="col-md-2" style="padding: 0 5px">
                                                    <div :class="{'has-error': $v.operation.invoice_price.$error}">
                                                        <label for="price" class="control-label" v-if="operation.type == 'Materiál'">Prodejní cena</label>
                                                        <label for="price" class="control-label" v-else>Fakturovaná cena</label>
                                                        <input type="number" class="form-control" id="price" v-model="$v.operation.invoice_price.$model">
                                                        <span v-if="!$v.operation.invoice_price.required" class="help-block">Povinný údaj</span>
                                                        <span v-if="!$v.operation.invoice_price.decimal" class="help-block">Musí být číslo!</span>
                                                        <span v-if="!$v.operation.invoice_price.minValue" class="help-block">Číslo musí být kladné!</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div class="col-md-1" style="padding: 0 5px">
                                                    <div :class="{'has-error': $v.operation.amount.$error}">
                                                        <label for="amount" class="control-label">Čas</label>
                                                        <input id="amount" class="form-control" v-model="$v.operation.amount.$model" type="number">
                                                        <span v-if="!$v.operation.amount.required" class="help-block">Povinný údaj</span>
                                                        <span v-if="!$v.operation.amount.decimal" class="help-block">Musí být číslo!</span>
                                                        <span v-if="!$v.operation.amount.minValue" class="help-block">Číslo musí být kladné!</span>
                                                    </div>
                                                </div>
                                                <div class="col-md-2" style="padding: 0 5px">
                                                    <div :class="{'has-error': $v.operation.invoice_price.$error}">
                                                        <label for="price" class="control-label">Hodinová cena</label>
                                                        <input type="number" class="form-control" id="price" v-model="$v.operation.invoice_price.$model">
                                                        <span v-if="!$v.operation.invoice_price.required" class="help-block">Povinný údaj</span>
                                                        <span v-if="!$v.operation.invoice_price.decimal" class="help-block">Musí být číslo!</span>
                                                        <span v-if="!$v.operation.invoice_price.minValue" class="help-block">Číslo musí být kladné!</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-1" style="padding: 0 5px">
                                                <div :class="{'has-error': $v.operation.dph.$error}">
                                                    <label for="dph" class="control-label">Sazba DPH</label>
                                                    <Select2 v-model="$v.operation.dph.$model" :disabled="!invoiceInfo.taxed" :options="[21, 12, 0]" id="select-dph"/>
                                                    <!-- <input id="dph" class="form-control" v-model="$v.operation.dph.$model" :disabled="!invoiceInfo.taxed" type="number"> -->
                                                    <span v-if="!$v.operation.dph.required" class="help-block">Povinný údaj</span>
                                                    <span v-if="!$v.operation.dph.decimal" class="help-block">Musí být číslo!</span>
                                                    <span v-if="!$v.operation.dph.minValue" class="help-block">Číslo musí být kladné!</span>
                                                </div>
                                            </div>
                                            <div class="col-md-2" style="padding: 0 5px">
                                                <label for="responsible" class="control-label">Mechanik</label>
                                                <Select2 v-model="operation.employee" id="responsible" :options='getEmployees()'/>
                                            </div>

                                            <div class="col-md-1">
                                                <span class="input-group-btn" style="display: block; margin-top: 40%">
                                                    <button @click="createOperation()" type="button" class="btn btn-primary btn-flat">Přidat</button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="box-footer">
                                        <p><b>Seznam operací</b></p>
                                        <table class="table table-hover">
                                            <thead>
                                            <tr>
                                                <th v-for="head in this.headColumns" :key="head.title">{{head.name}}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="operation in this.ops" v-bind:key="operation.order">
                                                    <td>{{operation.order}}</td>
                                                    <td>{{operation.description}}</td>
                                                    <td>{{operation.type == 0 ? "Práce" : "Materiál"}}</td>
                                                    <td>{{operation.invoice_price}}</td>
                                                    <td>{{operation.dph}}</td>
                                                    <td>{{operation.amount}}</td>
                                                    <td>{{operation.employee}}</td>
                                                    <td v-if="editTgl"><button type="button" class="btn btn-danger btn-sm" @click="removeOperation(operation.order)">Odstranit záznam</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" style="margin: 0;">
                            <div class="col-md-12">
                                <div class="box box-widget widget-user-2">
                                    <div class="box-header with-border" v-if="editTgl">
                                        <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving || isSuccess">
                                            <h3>Nahrát soubory</h3>
                                            <div class="dropbox">
                                                <input type="file" multiple name="files" :disabled="isSaving" @change="filesChange($event.target.files); fileCount = $event.target.files.length"
                                                accept="image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.txt" class="input-file">
                                                <p v-if="isInitial">
                                                    Přetáhněte soubory do boxu<br> nebo klikněte pro výběr souborů
                                                </p>
                                                <p v-if="isSaving">
                                                    Nahrávání {{ fileCount }} souborů...
                                                </p>
                                                <p v-if="isSuccess">
                                                    Nahráno {{ fileCount }} souborů<br> klikněte znovu pro nahrání dalších souborů
                                                </p>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="box-footer">
                                        <p><b>Seznam souborů</b></p>
                                        <table class="table table-hover">
                                            <thead>
                                            <tr>
                                                <th>Soubor</th>
                                                <th>Nahráno</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="file in this.currentIssueFiles" v-bind:key="file.fileid">
                                                    <td><a :href="filesUrl + file.carid + '/' + file.filename" :download="file.originalname">{{file.originalname}}</a></td>
                                                    <td>{{getTime(file.uploaded)}}</td>
                                                    <td v-if="editTgl"><button type="button" class="btn btn-danger btn-sm" @click="removeFile(file.fileid)">Odstranit</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div v-if="editTgl" class="box box-widget">
                        <div class="box-footer">
                            <button type="button" class="btn btn-success" :disabled="$v.formResponses.$invalid" @click="issueUpdate()">Uložit změny</button>
                            <div class="pull-right">
                                <button type="button" class="btn btn-danger" v-if="!user.cus" @click="issueDelete()">Odstranit záznam</button>
                                <button type="button" style="margin-left: 10px" class="btn btn-default" @click="closeEditIssue()">Zrušit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="display: none">
        <section id="issue-print-container" ref="issuePdf">
            <div class="row" style="margin-bottom: 1rem">
                <div class="col-xs-12">
                    <div style="display: flex; align-items: end">
                        <div style="width: 50%; display: flex; border: 1px solid black; justify-content: space-between; align-items: center; padding: 1rem">
                            <span><b style="font-size: 2rem">ZAKÁZKOVÝ LIST</b></span><span>č. {{currentIssue.visible_id}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-bottom: 1rem">
                <div class="col-xs-12">
                    <div style="padding: 1rem; border: 1px solid black">
                        <ul style="list-style-type: none; margin: 0">
                            <li><b>Název zakázky:</b> {{currentIssue.name}}</li>
                            <li><b>Předpokládaná cena opravy s DPH:</b> {{currentIssue.issue_price}}</li>
                            <li><b>Kontaktní osoba:</b> {{invoiceInfo.company_name}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-6" style="padding-right: 0">
                    <div style="border: 1px solid black; margin-bottom: 1rem; padding: 1rem">
                        <p><b>ZHOTOVITEL:</b></p>
                        <ul style="list-style-type: none; margin: 0">
                            <li>{{this.invoiceInfo.company_name}}</li>
                            <li>{{this.invoiceInfo.street}}</li>
                            <li>{{this.invoiceInfo.city}} {{this.invoiceInfo.zip}}</li>
                            <li><br/></li>
                            <li>Bankovní účet: {{this.invoiceInfo.bank_account}}</li>
                            <li>IČO: {{this.invoiceInfo.ico}}</li>
                            <li>DIČ: {{this.invoiceInfo.dic}}</li>
                        </ul>
                        <p>{{this.invoiceInfo.business_register}}</p>
                    </div>
                </div>
                <div class="col-xs-6" style="padding-left: 1rem">
                    <div style="border: 1px solid black; margin-bottom: 1rem; padding: 1rem">
                        <p><b>ODBĚRATEL:</b></p>
                        <ul style="list-style-type: none; margin: 0">
                            <li>{{currentIssue.company_name}}</li>
                            <li>{{currentIssue.invoice_street}}</li>
                            <li>{{currentIssue.invoice_city}} {{currentIssue.invoice_zip}}</li>
                            <li><br/></li>
                            <li><br/></li>
                            <li>IČO: {{currentIssue.ico}}</li>
                            <li>DIČ: {{currentIssue.dic}}</li>
                            <li>Jméno kontaktní osoby: {{currentMaintainer.fullname}}</li>
                            <li>Email kontaktní osoby: {{currentMaintainer.email}}</li>
                            <li>Telefon kontaktní osoby: {{currentMaintainer.phone}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <div style="border: 1px solid black; margin-bottom: 1rem; padding: 1rem">
                        <p><b>VOZIDLO:</b></p>
                        <div class="row">
                            <div class="col-xs-6">
                                <ul style="list-style-type: none; margin: 0">
                                    <li><b>Název vozu: </b>{{currentIssue.car_name}}</li>
                                    <li><b>VIN: </b>{{currentIssue.vin_code}}</li>
                                    <li><b>SPZ: </b>{{currentIssue.licence_plate}}</li>
                                    <li><b>Stav KM: </b>{{currentIssue.kilometers}}</li>
                                </ul>
                            </div>
                            <div class="col-xs-6">
                                <ul style="list-style-type: none; margin: 0">
                                    <li><b>STK do: </b>{{new Date(currentIssue.stk_expire * 1000).toLocaleString()}}</li>
                                    <li><b>Vozidlo přijato do opravy: </b>{{new Date(currentIssue.due_from * 1000).toLocaleString()}}</li>
                                    <li><b>Předpokládaný datum opravy: </b>{{new Date(currentIssue.due_to * 1000).toLocaleString()}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12" style="margin-bottom: 1rem">
                    <div style="padding: 1rem; border: 1px solid black">
                        <p><b>POŽADAVKY OBJEDNATELE:</b></p>
                        <span>{{currentIssue.comment}}</span>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-bottom: 1rem">
                <div class="col-xs-6" style="padding-right: 0">
                    <div style="padding: 1rem; border: 1px solid black">
                        <ul style="list-style-type: none; margin: 0">
                            <li><b>PŘEDÁNÍ VOZU DO OPRAVY:</b></li>
                            <li><b>Podpis objednatele: </b></li>
                            <li><br/></li>
                            <li><br/></li>
                            <li><b>Převzal: </b>{{invoiceInfo.company_name}}</li>
                            <li><b>Datum: </b>{{new Date().toLocaleString()}}</li>
                        </ul>
                    </div>
                </div>
                <div class="col-xs-6" style="padding-left: 1rem">
                    <div style="padding: 1rem; border: 1px solid black">
                        <ul style="list-style-type: none; margin: 0">
                            <li><b>PŘEDÁNÍ VOZU PO OPRAVĚ:</b></li>
                            <li><b>Podpis zhotovitele: </b></li>
                            <li><br/></li>
                            <li><br/></li>
                            <li><b>Převzal: </b>{{currentMaintainer.fullname}}</li>
                            <li><b>Datum: </b>{{currentIssue.closed > 0 ? new Date(currentIssue.closed * 1000).toLocaleString() : ""}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <div style="padding: 1rem; border: 1px solid black">
                        <table style="width: 100%">
                            <tbody>
                                <tr>
                                    <td>Vystavil: </td>
                                    <td style="text-align: right">{{new Date(currentIssue.added * 1000).toLocaleString()}}</td>
                                </tr>
                                <tr>
                                    <td>Vytiskl:</td>
                                    <td style="text-align: right">{{new Date().toLocaleString()}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <p style="text-align: center"><small>Zakázkový list byl vygenerován systémem ANASAP</small></p>
        </section>
        <section id="pdf-container" ref="pdf" style="margin-top: 1rem">
            <div class="row" style="margin-bottom: 1rem">
                <div class="col-xs-12">
                    <div style="display: flex; align-items: end">
                        <div style="width: 50%; display: flex; border: 1px solid black; justify-content: space-between; align-items: center; padding: 1rem">
                            <!-- Pro případ úkonů se starým číslováním faktur se zobrazuje visible_id v případě, že číslo faktury není -->
                            <span><b style="font-size: 2rem">FAKTURA</b>{{invoiceInfo.taxed ? " - Daňový doklad" : " - Nedaňový doklad"}}</span><span>č. {{currentIssue.invoice_number == 0 ? currentIssue.visible_id : currentIssue.invoice_number}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-6" style="padding-right: 0">
                    <div style="border: 1px solid black; margin-bottom: 1rem; padding: 1rem">
                        <p><b>ZHOTOVITEL:</b></p>
                        <ul style="list-style-type: none; margin: 0">
                            <li>{{this.invoiceInfo.company_name}}</li>
                            <li>{{this.invoiceInfo.street}}</li>
                            <li>{{this.invoiceInfo.city}} {{this.invoiceInfo.zip}}</li>
                            <li><br/></li>
                            <li>Bankovní účet: {{this.invoiceInfo.bank_account}}</li>
                            <li>IČO: {{this.invoiceInfo.ico}}</li>
                            <li v-if="invoiceInfo.taxed">DIČ: {{this.invoiceInfo.dic}}</li>
                        </ul>
                        <span>{{this.invoiceInfo.business_register}}</span>
                    </div>
                </div>
                <div class="col-xs-6" style="padding-left: 1rem">
                    <div style="border: 1px solid black; margin-bottom: 1rem; padding: 1rem">
                        <p><b>ODBĚRATEL:</b></p>
                        <ul style="list-style-type: none; margin: 0">
                            <li>{{currentIssue.company_name}}</li>
                            <li>{{currentIssue.invoice_street}}</li>
                            <li>{{currentIssue.invoice_city}} {{currentIssue.invoice_zip}}</li>
                            <li><br/></li>
                            <li><br/></li>
                            <li>IČO: {{this.currentIssue.ico}}</li>
                            <li>DIČ: {{this.currentIssue.dic}}</li>
                        </ul>
                        <span><br/></span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-6" style="padding-right: 0">
                    <div style="border: 1px solid black; padding: 1rem">
                        <p><b>VOZIDLO:</b></p>
                        <ul style="list-style-type: none; margin: 0">
                            <li><b>Jméno: </b>{{currentIssue.car_name}}</li>
                            <li><b>VIN: </b>{{currentIssue.vin_code}}</li>
                            <li><b>SPZ: </b>{{currentIssue.licence_plate}}</li>
                        </ul>
                    </div>
                </div>
                <div class="col-xs-6" style="padding-left: 1rem">
                    <div style="border: 1px solid black; margin-bottom: 1rem; padding: 1rem">
                        <p><b>FAKTURAČNÍ ÚDAJE:</b></p>
                        <ul style="list-style-type: none; margin: 0">
                            <li><b>Forma úhrady: </b>{{paymentType}}</li>
                            <li><b>Datum vystavení: </b>{{currentInvoice.created}}</li>
                            <li><b>Datum splatnosti: </b>{{currentInvoice.due_date}}</li>
                            <li v-if="invoiceInfo.taxed" ><b>Datum UZP: </b>{{currentInvoice.uzp_date}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12" :style="{marginBottom: invoiceInfo.taxed ? '1rem' : ''}">
                    <div style="border: 1px solid black">
                        <div style="padding: 1rem; padding-bottom: 0">
                            <p><b>ROZSAH A PŘEDMĚT ZDANITELNÉHO PLNĚNÍ:</b></p>
                        </div>
                        <table class="table table-condensed" style="margin-bottom: 0">
                            <thead>
                            <tr>
                                <th>Popis</th>
                                <th>Typ</th>
                                <th>Prodejní cena/Hodinová cena</th>
                                <th v-if="invoiceInfo.taxed">DPH</th>
                                <th>Fakturovaný čas/Množství</th>
                                <th v-if="!invoiceInfo.taxed"></th>
                                <th>{{invoiceInfo.taxed ? "Celkem s DPH" : "Celkem"}}</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="operation in this.ops" v-bind:key="operation.order">
                                    <td>{{operation.optitle}}</td>
                                    <td>{{operation.type == 0 ? "Práce" : "Materiál"}}</td>
                                    <td>{{operation.invoice_price}}</td>
                                    <td v-if="invoiceInfo.taxed" style="white-space: nowrap">{{operation.dph}} %</td>
                                    <td>{{operation.amount}}</td>
                                    <td v-if="!invoiceInfo.taxed"></td>
                                    <td>{{Math.round(((operation.amount * operation.invoice_price * ((operation.dph / 100) + 1)) + Number.EPSILON) * 100) / 100}}</td>
                                </tr>
                                <tr v-if="this.currentInvoice.payment_type === 1">
                                    <td style="border-top: 1px solid black">Zaokrouhleno</td>
                                    <td style="border-top: 1px solid black"></td>
                                    <td style="border-top: 1px solid black"></td>
                                    <td style="border-top: 1px solid black"></td>
                                    <td style="border-top: 1px solid black"></td>
                                    <td style="border-top: 1px solid black">{{this.addMoney}}</td>
                                </tr>
                                <tr style="font-weight: bold">
                                    <td>CELKEM K ÚHRADĚ</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{{this.formatNumber(formResponses.final_price_dph + this.addMoney)}} Kč</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row avoid-pdf-break" v-if="invoiceInfo.taxed">
                <div class="col-xs-12">
                    <div style="border: 1px solid black">
                        <div style="padding: 1rem; padding-bottom: 0">
                            <p><b>REKAPITULACE DPH (Kč):</b></p>
                        </div>
                        <table class="table table-condensed" style="margin-bottom: 0; margin-left: 5px; width: 50%">
                            <thead>
                                <tr>
                                    <th>Sazba</th>
                                    <th>Daňový základ</th>
                                    <th>DPH</th>
                                    <th>Celkem</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Základní (21 %)</td>
                                    <td>{{this.dph21base}}</td>
                                    <td>{{this.dph21}}</td>
                                    <td>{{Math.round((this.dph21 + this.dph21base + Number.EPSILON) * 100) / 100}}</td>
                                </tr>
                                <tr>
                                    <td>Snížená (12 %)</td>
                                    <td>{{this.dph12base}}</td>
                                    <td>{{this.dph12}}</td>
                                    <td>{{Math.round((this.dph12 + this.dph12base + Number.EPSILON) * 100) / 100}}</td>
                                </tr>
                                <tr v-if="this.currentInvoice.payment_type === 1">
                                    <td>Osvobozeno</td>
                                    <td>{{this.addMoney}}</td>
                                    <td>0</td>
                                    <td>{{this.addMoney}}</td>
                                </tr>
                                <tr style="font-weight: bold">
                                    <td>Celkem</td>
                                    <td>{{Math.round((this.dph21base + this.dph12base + this.addMoney) * 100) / 100}}</td>
                                    <td>{{this.dph21 + this.dph12}}</td>
                                    <td>{{this.formatNumber(formResponses.final_price_dph + this.addMoney)}} Kč</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <p v-if="!this.invoiceInfo.taxed"><small>Servis není plátcem DPH</small></p>
            <p style="text-align: center"><small>Faktura byla vygenerována systémem ANASAP</small></p>
        </section>
        </div>
    </div>
</template>

<script>

const name = 'IssueDetail';

import { mapGetters, mapActions, mapMutations } from "vuex";
import Vue from 'vue'

import router from '../router';

// Select2
import Select2 from '../components/pageux/Select2';

// Vuelidate
import { required, maxLength, between, helpers, decimal, minValue, numeric, maxValue } from 'vuelidate/lib/validators';

const timeformat = helpers.regex('timeformat', /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
    name,
    components: {
        Select2,
    },
    data() {
        return {
            filesUrl: process.env.VUE_APP_FILES,

            page: null,

            // Values to be updated
            fromdate: null,
            todate: null,

            customerid: null,
            carid: null,

            name: null,
            comment: null,
            hidden_comment: null,
            invoice_time: null,

            ops: [],
            opId: -1,
            new_op: '',

            tagid: 1,
            formResponses: {
                name: null,
                issue_price: 0,
                comment: '',
                hidden_comment: '',
                invoice_time: null,
                final_price_no_dph: 0,
                final_price_dph: 0,
                visible_id: '',
                kilometers: 0
            },
            operation: {
                listOperation: null,
                order: 1,
                type: 'Práce',
                description: '',
                dph: 21,
                invoice_price: null,
                amount: null,
                price: null,
                employee: null
            },

            check: true,
            paymentType: null,
            dph21base: 0,
            dph21: 0,
            dph12base: 0,
            dph12: 0,
            addDays: 0,
            addMoney: 0,

            newStage: null,

            // Toggles
            editTgl: false,
            hideTp1xs: false,
            hideTp2xs: false,

            nextVisibleId: 0,

            uploadedFiles: [],
            uploadError: null,
            currentStatus: null,
        }
    },
    validations() {

        return {
            formResponses: {
                issue_price: {
                    required,
                    decimal,
                    minValue: minValue(0)
                },
                name: {
                    required,
                    maxLength: maxLength(128)
                },
                comment: {
                    maxLength: maxLength(1024)
                },
                hidden_comment: {
                    maxLength: maxLength(1024)
                },
                invoice_time: {
                    between: between(0, 999),
                },
                visible_id: {
                    required
                },
            },
            operation: {
                description: {
                    required,
                    maxLength: maxLength(64)
                },
                dph: {
                    required,
                    decimal,
                    minValue: minValue(0)
                },
                invoice_price: {
                    required,
                    decimal,
                    minValue: minValue(0)
                },
                price: {
                    decimal,
                    minValue: minValue(0)
                },
                amount: {
                    required,
                    decimal,
                    minValue: minValue(0)
                }
            },
            new_op: {
                maxLength: maxLength(64)
            }
        }
    },
    computed: {
        ...mapGetters(['getAllPages', 'minMaintainers', 'currentIssue', 'currentIssueFiles', 'minCars', 'minCustomers', 'currentIssueId', 'user', 'group', 'allTags', 'allListOperations', 'employees', 'invoiceInfo', 'currentInvoice', 'currentCustomer', 'currentMaintainer']),
        maxOps(){
            if (this.ops != null)
                return this.ops.length <= 15;
            return true;
        },
        isInitial() {
            return this.currentStatus === STATUS_INITIAL;
        },
        isSaving() {
            return this.currentStatus === STATUS_SAVING;
        },
        isSuccess() {
            return this.currentStatus === STATUS_SUCCESS;
        },
        isFailed() {
            return this.currentStatus === STATUS_FAILED;
        },
    },
    methods: {
        ...mapActions([
            'fetchMinCustomers', 'fetchMinCars', 'fetchCustomerMinCars', 'fetchMinMaintainers',
            'makeActiveTab', 'addTab', 'closeTab',
            'fetchIssue', 'fetchTags', 'fetchInvoice',
            'makeCurrentCar', 'makeCurrentMnt', 'makeCurrentCus', 'createInvoice', 'deleteInvoice',
            'updateOperation', 'addOperation', 'deleteIssueOperation', 'updateIssue', 'deleteIssue', 'fetchEmployees', 'fetchListOperations',
            "fetchInvoiceInfo", 'updateListOperation', 'addListOperation', 'fetchCustomer', 'fetchMaintainer', 'fetchIssueNumber', 'fetchIssueFiles', 'deleteIssueFile', 'uploadIssueFile'
        ]),
        reset() {
            // reset form to initial state
            this.currentStatus = STATUS_INITIAL;
            this.uploadedFiles = [];
            this.uploadError = null;
        },
        save(formData) {
            // upload data to the server
            this.currentStatus = STATUS_SAVING;

            this.uploadIssueFile({ formData: formData, id: this.carid == null ? this.currentIssue.carid : this.carid})
            .then(x => {
                this.uploadedFiles = [].concat(x);
                this.currentStatus = STATUS_SUCCESS;
                this.reset();
            })
            .catch(err => {
                this.uploadError = err.response;
                this.currentStatus = STATUS_FAILED;
                this.reset();
            });
        },
        filesChange(fileList) {
            // handle file changes
            const formData = new FormData();

            if (!fileList.length) return;

            // append the files to FormData
            Array
            .from(Array(fileList.length).keys())
            .map(x => {
                formData.append("files", fileList[x], fileList[x].name);
            });

            formData.append("issueid", this.currentIssueId)

            // save it
            this.save(formData);
        },
        removeFile(id) {
            this.$alerts.confirmDelete('Opravdu chcete tento soubor smazat?', "").then((result) => {
                if (result.value) {
                    this.deleteIssueFile(id);
                }
            });

        },
        getListOperationDescriptions()
        {
            var arr = []

            for (var i = 0; i < this.allListOperations.length; i++)
            {
                arr.push(this.allListOperations[i].description)
            }

            return arr
        },
        formatNumber(x)
        {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        },
        convertToStkTime(date) {
            if (date != null) {
                let d = new Date(date * 1000)
                let year = d.getFullYear()
                let month = d.getMonth() + 1
                return `${year}/${month}`
            }
        },

        getEmployees()
        {
            var arr = []

            for (var i = 0; i < this.employees.length; i++)
            {
                var employee = this.employees[i]

                arr.push(employee.employee ? employee.fullname : "Servis")
            }

            return arr
        },
        print: function() {
            window.print();
        },
        setListOperation()
        {
            var newValues = this.allListOperations.filter((listOperation) => { return listOperation.description == this.operation.listOperation })

            if (newValues.length)
            {
                newValues = newValues[0]
                this.opId = newValues.id
                this.operation.price = newValues.price
                this.operation.description = newValues.description
                this.operation.invoice_price = newValues.invoice_price
                this.operation.dph = this.invoiceInfo.taxed ? newValues.dph : 0
                this.operation.amount = newValues.amount
                this.operation.type = newValues.type == 0 ? 'Práce' : "Materiál"
            }
        },
        //YYYY-MM-DD HH:mm
        //DD.MM.YYYY HH:mm
        getTime(t){
            if (!t){
              return null;
            }
            const dt = new Date(t*1000);
            let day = dt.getDate();
            let mth = dt.getMonth() + 1;
            const yrs = dt.getFullYear();
            let hrs = dt.getHours().toString();
            let min = dt.getMinutes();

            if (hrs < 10){
              hrs = '0' + hrs.toString();
            }
            if (min < 10){
              min = '0' + min.toString();
            }
            if (day < 10){
              day = '0' + day.toString();
            }
            if (mth < 10){
              mth = '0' + mth.toString();
            }
             return `${day}.${mth}.${yrs} ${hrs}:${min}`;
            //return `${day}. ${mth}. ${yrs} ${hrs}:${min}`;
        },
        getDate(t){
            if (t === null || t === undefined)
            {
                return ""
            }

            const dt = new Date(t*1000);
            const day = dt.getDate();
            const mth = dt.getMonth() + 1;
            const yrs = dt.getFullYear();
            return `${day}.${mth}.${yrs}`;
        },

        issueStageChange(stage){

            var updateData;

            if(stage == 3) {
                updateData = {
                    issueid: this.currentIssueId,
                    tagid: stage,
                    billing_price_dph: this.formResponses.final_price_dph,
                    billing_price_no_dph: this.formResponses.final_price_no_dph
                }
            } else {
                updateData = {
                    issueid: this.currentIssueId,
                    tagid: stage,
                    billing_price_dph: null,
                    billing_price_no_dph: null
                }
            }

            this.updateIssue(updateData).then(() => {
                this.$alerts.toast('Stav úspěšně změněn.', 'success')
                this.fetchIssue(this.currentIssueId).then(() => { this.ops = this.currentIssue.ops; this.setOrder() })
            }).catch(err => this.$alerts.message('Nastala chyba', err, 'error'));
        },

        issueDelete(){
            this.$alerts.confirmDelete('Opravdu chcete tento záznam smazat?', 'Odstraní se s ním i jeho operace.').then((result) => {
                if (result.value) {
                    this.ops.forEach(async op => {
                        if (op.opid != -1) {
                            this.deleteIssueOperation(op.opid)
                        }
                    })
                    this.deleteIssue(this.currentIssueId).then(() => {
                        this.closeTab(this.page.id);
                        this.$alerts.message('Záznam úspěšně smazán', '', 'success')
                    });
                }
            });
        },

        updateOp(id){
            let op = this.currentIssue.ops.find(el => {return el.opid == id});
            op.opdone = op.opdone ? 1 : 0;
            this.updateOperation(op);
        },

        // *Use vuelidate here (similarly to the)
        createOp(){
            if (this.new_op.length > 64 || !this.maxOps || this.new_op.trim() == '' )
                return null;

            const op = {
                optitle: this.new_op.trim()
            }
            this.addOperation(op);
            this.new_op = '';
        },
        async createOperation()
        {
            if (this.$v.operation.$invalid || this.operation.description.trim() == '')
            {
                return null;
            }

            let op = {
                description: this.operation.description,    // optitle
                invoice_price: this.operation.invoice_price,
                dph: this.operation.dph,
                amount: this.operation.amount,
                price: this.operation.price,
                type: this.operation.type == "Práce" ? 0 : 1,
                order: this.ops.length && this.ops[this.ops.length - 1].order == this.operation.order ? this.operation.order + 1 : this.operation.order,
                employee: this.operation.employee,
                id: this.opId
            }
            // if (this.opId == -1) {
            //     op.description = this.operation.description
            // }
            // else {
            //     op.optitle = this.operation.optitle
            // }
            delete op.employee
            delete op.order
            let ok = false
            if (op.id != -1) {
                const res = await this.updateListOperation(op)
                if (res.code != "LIST_OPERATION_UPDATED") {
                    this.$alerts.message('Došlo k chybě při přidání operace.', "", 'error');
                }
                else {
                    ok = true
                }
            }
            else {
                op.amount = parseFloat(op.amount)
                op.invoice_price = parseFloat(op.invoice_price)
                op.dph = parseInt(op.dph)
                delete op.id
                op.type = op.type == 0 ? "Práce" : "Materiál"
                const res = await this.addListOperation(op)
                if (res.code != "LIST_OPERATION_ADDED") {
                    this.$alerts.message('Došlo k chybě při přidání operace.', "", 'error');
                }
                else {
                    ok = true
                }
                op.type = op.type == "Práce" ? 0 : 1
            }
            op.employee = this.operation.employee
            op.order = this.ops.length && this.ops[this.ops.length - 1].order == this.operation.order ? this.operation.order + 1 : this.operation.order

            this.opId = -1

            this.operation.listOperation = null,
            this.operation.type = 'Práce',
            this.operation.description = '',
            this.operation.dph = this.invoiceInfo.taxed ? 21 : 0,
            this.operation.invoice_price = null,
            this.operation.amount = null,
            this.operation.price = null
            this.operation.employee = null

            this.ops.push(op);
            this.operation.order = this.ops.length + 1

            this.calculatePrice()
        },
        calculatePrice()
        {
            var totalNoDPH = 0, totalWithDPH = 0;
            this.dph21base = 0;
            this.dph12base = 0;

            this.ops.forEach((op) => {
                totalNoDPH += Math.round(((op.amount * op.invoice_price) + Number.EPSILON) * 100) / 100
                totalWithDPH += Math.round(((op.amount * op.invoice_price * ((op.dph / 100) + 1)) + Number.EPSILON) * 100) / 100

                if (op.dph === 21)
                {
                    this.dph21base += (op.invoice_price * op.amount)
                }
                else if (op.dph === 12)
                {
                    this.dph12base += (op.invoice_price * op.amount)
                }
            })

            this.dph21 = Math.round(((this.dph21base * 0.21) + Number.EPSILON) * 100) / 100
            this.dph12 = Math.round(((this.dph12base * 0.12) + Number.EPSILON) * 100) / 100

            this.dph21base = Math.round((this.dph21base + Number.EPSILON) * 100) / 100
            this.dph12base = Math.round((this.dph12base + Number.EPSILON) * 100) / 100

            totalWithDPH = Math.round((totalWithDPH + Number.EPSILON) * 100) / 100
            totalNoDPH = Math.round((totalNoDPH + Number.EPSILON) * 100) / 100

            this.formResponses.final_price_no_dph = totalNoDPH
            this.formResponses.final_price_dph = totalWithDPH
        },
        removeOperation(order)
        {
            for (var i = 0; i < this.ops.length; i++)
            {
                if (this.ops[i].order == order)
                {
                    for (var j = i; j < this.ops.length; j++)
                    {
                        this.ops[j].order = this.ops[j].order - 1
                    }

                    if (this.ops[i].opid)
                    {
                       this.deleteIssueOperation(this.ops[i].opid)
                    }

                    this.ops.splice(i, 1)
                    this.operation.order = this.ops.length + 1

                    this.calculatePrice();
                    return
                }
            }
        },

        // Pushing current values to forms
        editIssue() {
            this.formResponses.name = this.currentIssue.name;
            this.formResponses.invoice_time = this.currentIssue.invoice_time;
            this.tagid = this.currentIssue.tagid;


            // Editing current info about due dates for these forms
          //  this.formResponses.fromtime = this.fromtime.split(" ")[3]
            this.fromdate = new Date(this.currentIssue.due_from*1000);

         //   this.formResponses.totime = this.totime.split(" ")[3]
            this.todate = new Date(this.currentIssue.due_to*1000);

            this.editTgl = true;
        },


        // Need to push back original values of commets (because of form component)
        closeEditIssue (){
            this.formResponses.comment = this.currentIssue.comment || '';
            this.formResponses.hidden_comment = this.currentIssue.hidden_comment || '';

            this.editTgl = false;
        },

        // Pushing changes to the DB
        async issueUpdate(){
            // Check if whole form is valid
            if (this.$v.formResponses.$invalid)
                return 0;

            this.ops.forEach((operation) => {
                this.employees.forEach((employee) => {
                    if (employee.fullname == operation.employee || (!employee.employee && employee.fullname == undefined))
                    {
                        operation.employeeid = employee.accountid
                    }
                })
            })

            this.ops.forEach((op) => {
                op.optitle = op.description
                const res = this.addOperation(op)
            })
            const values = {
                customerid: this.customerid,
                carid: this.carid == null ? this.currentIssue.carid : this.carid,
                issueid: this.currentIssueId,
                tagid: (this.ops.length && this.currentIssue.tagid == 1) ? 4 : this.tagid,
                issue_price: this.formResponses.issue_price,
                ops: this.ops,
                name: this.formResponses.name,
                comment: this.formResponses.comment,
                hidden_comment: this.formResponses.hidden_comment,
                invoice_time: this.formResponses.invoice_time,
                due_from: Date.parse(this.fromdate)/1000,
                due_to: Date.parse(this.todate)/1000,
                visible_id: this.$v.formResponses.visible_id.$dirty ? this.formResponses.visible_id : null,
                kilometers: this.formResponses.kilometers != 0 ? this.formResponses.kilometers : null
            }
            this.$v.formResponses.visible_id.$reset();
            // // Write changes to the backend
            const res = await this.updateIssue(values);

            if(res.status == 200) {
                this.$alerts.toast('Změny úspěšně zapsány.', 'success')
                this.editTgl = false;
                this.refresh();
                // Getting changed values from server (for clarity of data)
                //this.getIssueData();
                this.ops = this.ops.filter(op => {
                    return typeof op.issueid == "number"
                })
            } else if(res.status == 400) {
                this.$alerts.message('Neplatné číslo servisního úkonu', "Další možná volná hodnota je: " + res.data.nextVisibleId, 'error')
            } else {
                this.$alerts.message('Nastala chyba', res.data.msg, 'error')
            }
        },
        generateReport () {
            var opt = {
                margin:       0.5,
                filename:     'Faktura.pdf',
                image:        { type: 'jpeg', quality: 0.98 },
                html2canvas:  { scale: 2, scrollX: 0, scrollY: 0 },
                jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' },
                pagebreak:    { avoid: '.avoid-pdf-break'}
            };

            window.html2pdf().from(this.$refs.pdf).set(opt).toPdf().get('pdf').then(function (pdf) {
                window.open(pdf.output('bloburl'), '_blank');
            });
        },
        generatePrintIssue () {
            var opt = {
                margin:       0.5,
                filename:     'Úkon.pdf',
                image:        { type: 'jpeg', quality: 0.98 },
                html2canvas:  { scale: 2, scrollX: 0, scrollY: 0 },
                jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
            };

            window.html2pdf().from(this.$refs.issuePdf).set(opt).toPdf().get('pdf').then(function (pdf) {
                window.open(pdf.output('bloburl'), '_blank');
            });
        },
        invoice()
        {
            const generateInvoice = (id) => {
                this.fetchInvoice(id).then(() => {
                    if (this.currentInvoice.payment_type === 1)
                    {
                        this.paymentType = "Hotově"
                        this.addDays = 0
                        this.addMoney = Math.round(((Math.round(this.formResponses.final_price_dph) - this.formResponses.final_price_dph) + Number.EPSILON) * 100) / 100
                    }
                    else if (this.currentInvoice.payment_type === 2)
                    {
                        this.paymentType = "Kartou"
                        this.addDays = 0
                    }
                    else if (this.currentInvoice.payment_type === 3)
                    {
                        this.paymentType = "Převod na účet"
                        this.addDays = 14
                    }

                    this.generateReport()
                })
            }

            if (this.currentIssue.invoice_id)
            {
                generateInvoice(this.currentIssue.invoice_id)
            }
            else
            {
                Vue.swal({
                    title: "Údaje k faktuře",
                    showCancelButton: true,
                    cancelButtonText: "Zrušit",
                    confirmButtonText: "Potvrdit",
                    icon: "question",
                    html: `
                        <div style="display: flex; flex-direction: column; gap: 0.8rem">
                            <div style="display: flex; flex-direction: row; justify-content: space-between">
                                <span>Forma úhrady:</span>
                                <div class="input-group" id="payment-select">
                                    <button type="button" class="btn btn-default btn-info" data-id="1">Hotově</button>
                                    <button type="button" class="btn btn-default btn-flat" data-id="2">Kartou</button>
                                    <button type="button" class="btn btn-default btn-flat" data-id="3">Převod na účet</button>
                                </div>
                            </div>
                            <div style="display: flex; flex-direction: row; justify-content: space-between">
                                <span>Datum vystavení:</span>
                                <input id="invoice-create-date" type="date" />
                            </div>
                            <div style="display: flex; flex-direction: row; justify-content: space-between">
                                <span>Datum splatnosti:</span>
                                <input id="invoice-due-date" type="date" />
                            </div>
                            <div style="display: flex; flex-direction: row; justify-content: space-between">
                                <span>Datum UZP:</span>
                                <input id="invoice-uzp-date" type="date" />
                            </div>
                        </div>
                    `,
                    onOpen: (body) => {

                        if(!this.invoiceInfo.taxed) {
                            body.querySelector("#invoice-uzp-date").toggleAttribute("disabled", true);
                        }

                        body.querySelector("#invoice-uzp-date").valueAsDate = new Date();
                        body.querySelector("#invoice-create-date").valueAsDate = new Date();
                        body.querySelector("#invoice-due-date").valueAsDate = new Date();

                        body.querySelectorAll("#payment-select>button.btn").forEach((button, i) => {
                            button.addEventListener("click", () => {
                                body.querySelectorAll("#payment-select>button.btn").forEach((buttonTemp) => {
                                    buttonTemp.classList.remove("active", "btn-info")
                                    buttonTemp.classList.add("btn-flat")
                                })

                                var due = new Date();

                                if(button.getAttribute("data-id") == 1) {
                                    body.querySelector("#invoice-due-date").valueAsDate = due;
                                } else if(button.getAttribute("data-id") == 2) {
                                    due.setUTCDate(due.getUTCDate() + 7);
                                    body.querySelector("#invoice-due-date").valueAsDate = due;
                                } else if(button.getAttribute("data-id") == 3) {
                                    due.setUTCDate(due.getUTCDate() + 14);
                                    body.querySelector("#invoice-due-date").valueAsDate = due;
                                }

                                button.classList.remove("btn-flat")
                                button.classList.add("active", "btn-info")
                            })
                        })
                    },
                    preConfirm: () => {
                        const paymentType = document.querySelector("button.btn-info").getAttribute("data-id")
                        const issueDate = document.querySelector("#invoice-create-date").valueAsDate.toISOString().replace("Z", "")
                        const uzpDate = document.querySelector("#invoice-uzp-date").valueAsDate.toISOString().replace("Z", "")
                        const dueDate = document.querySelector("#invoice-due-date").valueAsDate.toISOString().replace("Z", "")
                        return {
                            paymentType: paymentType,
                            issueDate: issueDate,
                            uzpDate: uzpDate,
                            dueDate: dueDate,
                        }
                    }
                }).then((e) => {
                    if (e.isConfirmed)
                    {
                        this.createInvoice({ issue_id: this.currentIssueId, car_id: this.currentIssue.carid, payment_type: e.value.paymentType, created: e.value.issueDate, uzp_date: e.value.uzpDate, due_date: e.value.dueDate }).then((res) => {
                            if (res.msg == "Success.")
                            {
                                this.fetchIssue(this.currentIssueId).then(() => {
                                    generateInvoice(res.id)
                                })
                            }
                            else
                            {
                                this.$alerts.message('Nastala chyba', res, 'error')
                            }
                        })
                    }
                })
            }
        },
        setCustomerByCar() {
            if (this.customerid != null)
                return null

            this.minCars.forEach(item => {
                if (item.id == this.carid)
                {
                    this.customerid = item.customerid
                    this.fetchCustomerMinCars(item.customerid)
                }
            });
        },
        onCustomerSelect({id}) {
            this.carid = null
            this.fetchCustomerMinCars(id).then(() => {
                if (this.minCars.length === 1)
                {
                    this.carid = this.minCars[0].id
                }
            })
        },
        setOrder()
        {
            this.ops.forEach((op, i) => {
                op.order = i + 1;
                op.employee = this.employees.filter((employee) => op.employeeid == employee.accountid)[0]?.fullname

                if (op.employee == null)
                {
                    op.employee = "Servis";
                }
            })
            this.operation.order = this.ops.length + 1;
        },
        getIssueData()
        {
            this.fetchIssue(this.currentIssueId).then(()=>{
                this.ops = this.currentIssue.ops;
                this.setOrder();

                this.formResponses.issue_price = this.currentIssue.issue_price;
                this.calculatePrice()

                // Set values that can be updated
                this.formResponses.comment = this.currentIssue.comment || '';
                this.formResponses.hidden_comment = this.currentIssue.hidden_comment || '';
            });
        },
        reopenIssue() {
            if(this.currentIssue.invoice_id != 0) {
                this.$swal({
                    title: "Opravdu chcete tento úkon znovuotevřít?",
                    text: "Znovuotevření úkonu smaže stávající fakturu a při následném uzavření úkonu bude vytvořena nová faktura podle aktualizovaných hodnot v úkonu. Číslo faktury bude zachováno.",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Otevřít úkon",
                    cancelButtonText: "Zrušit"
                }).then((result) => {
                    if (result.value) {
                        this.deleteInvoice(this.currentIssue.invoice_id);
                        this.issueStageChange(this.currentIssue.ops.length ? 4 : 1);
                    }
                });
            } else {
                this.issueStageChange(this.currentIssue.ops.length ? 4 : 1);
            }
        },
        refresh() {
            this.fetchIssue(this.currentIssueId).then(()=>{
                this.ops = this.currentIssue.ops
                for (let i = 0; i < this.currentIssue.ops.length; i++) {
                    this.ops[i].description = this.currentIssue.ops[i].optitle
                }
                this.setOrder();
                this.fetchCustomer(this.currentIssue.customerid)
                this.fetchMaintainer(this.currentIssue.maintainerid)

                if (this.currentIssue.invoice_id)
                {
                    this.fetchInvoice(this.currentIssue.invoice_id)
                }

                this.formResponses.issue_price = this.currentIssue.issue_price;
                this.calculatePrice();

                // Set values that can be updated
                this.fromdate = this.getTime(this.currentIssue.due_from);
                this.todate = this.getTime(this.currentIssue.due_to);
                this.formResponses.comment = this.currentIssue.comment || '';
                this.formResponses.hidden_comment = this.currentIssue.hidden_comment || '';

                this.formResponses.visible_id = this.currentIssue.visible_id;

                this.page = this.getAllPages.filter(page => page.name == name)[0]
                this.addTab({...this.page, lng_name: this.currentIssue.name});
                this.makeActiveTab(this.page);

                this.formResponses.kilometers = this.currentIssue.kilometers;

                this.fetchIssueFiles(this.currentIssueId);
            });
        }
    },
    async created(){
        this.currentStatus = STATUS_INITIAL;
        this.fetchInvoiceInfo().then(() => {
            if(!this.invoiceInfo.taxed) {
                this.operation.dph = 0;
            }
        })

        if (this.currentIssueId != undefined || this.currentIssueId != null)
        {
            this.fetchEmployees();
            this.fetchListOperations()
            this.fetchTags()
            this.fetchMinCustomers()
            this.fetchMinCars()

            this.headColumns = [
                { name: 'Pořadí' },
                { name: 'Popis' },
                { name: 'Typ' },
                { name: 'Prodejní cena/Hodinová cena' },
                { name: 'DPH' },
                { name: 'Fakturovaný čas/Množství' },
                { name: 'Mechanik' },
                { name: '' }
            ]

            this.headColumnsInvoice = [
                { name: 'Popis' },
                { name: 'Typ' },
                { name: 'Prodejní cena/Hodinová cena' },
                { name: 'DPH' },
                { name: 'Fakturovaný čas/Množství' },
                { name: 'Celkem s DPH' },
            ]

            this.fetchIssueNumber().then((d) => {
                this.nextVisibleId = d.issue_number
            });

            this.refresh();
        } else {
            router.push({ name: 'Issues' })
        }
    }
}
</script>

<style scoped>

.dropbox {
    outline: 2px dashed grey;
    outline-offset: -10px;
    background: lightcyan;
    color: dimgray;
    padding: 10px 10px;
    min-height: 120px;
    position: relative;
    cursor: pointer;
}

.input-file {
    opacity: 0;
    width: 100%;
    height: 120px;
    position: absolute;
    cursor: pointer;
}

.dropbox:hover {
    background: lightblue;
}

.dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
}

section {
    margin-right: 10px;
}

/* Forbid arrows in input type number for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Forbid arrows in input type number for Firefox */
input[type=number] {
  -moz-appearance:textfield;
}

a {
    cursor: pointer;
}
.module .collapse, .module .collapsing {
    height: 3rem;
}

.module .collapse {
    position: relative;
    display: block;
    overflow: hidden;
}

.module .collapse:before {
    content: ' ...';
    position: absolute;
    right: 0;
    bottom: 0;
}

.module .collapse.show {
    height: auto;
}

.module .collapse.show:before {
    display: none;
}

.module a.collapsed:after {
    content: '+ Show More';
}

.module a:not(.collapsed):after {
    content: '- Show Less';
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.dph {
    font-weight: bold;
    font-size: 1.2em;
}

</style>

<style>
    .field-input[data-v-74507c40]{
        border-radius: 0px
    }

    section h1 {
        margin: 0.5rem 0;
    }

    @media print
    {
        #tab_selection *, .print-hide *
        {
            display: none !important;
        }
    }
</style>
